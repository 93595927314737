import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue")
    },
    {
      path: "/connect",
      name: "connect",
      component: () => import("@/views/Connect.vue")
    },
    {
      path: "/connect/medicare101",
      name: "medicare101",
      component: () => import("@/views/Medicare101.vue")
    },
    {
      path: "/smartconnect",
      redirect: "/connect"
    }
  ]
});
