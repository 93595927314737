import WebFont from "webfontloader";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMeta from "vue-meta";

Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  mounted: () => {
    WebFont.load({
      google: {
        families: ["Lato:400,900"]
      },
      custom: {
        families: ["foundation-icons"],
        urls: [
          "https://cdnjs.cloudflare.com/ajax/libs/foundicons/3.0.0/foundation-icons.css?ver=2.1.3.4"
        ]
      }
    });
    document.dispatchEvent(new Event("x-app-rendered"));
  }
}).$mount("#app");
